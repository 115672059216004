import { useAtomValue } from "jotai/react";
import type { ReactNode } from "react";
import { useNumberFormatter } from "react-aria";
import { siteCurrencyAtom } from "../../config.ts";
import { useCurrencyFormat, useSiteCurrencyFormat } from "../../modules/pricing/currencies.ts";
import { hourlyToMonthly } from "../../modules/pricing/hourlyToMonthly.ts";

interface CurrencyProps {
    value: number;
    /**
     * Maximum fractional digits to display.
     * When set to 0, the number is rounded up to the nearest integer.
     * Can be negative to start rounding non-fractional digits.
     */
    maximumFractionDigits?: number;
    /** Hide fractions when they are all zeros (eg. `2.00`) */
    hideTrailingZeroFractions?: boolean;
    /** Always display `0` value without fractions */
    noZeroFractions?: boolean;

    /** Currency to use for formatting when converting to a different currency */
    currency?: string;
}

/** NB: Do not use directly, create a wrapper component for a specific "price type". */
function Currency({
    value,
    maximumFractionDigits = 10,
    hideTrailingZeroFractions = false,
    noZeroFractions = true,
    currency,
}: CurrencyProps): ReactNode {
    const siteCurrency = useAtomValue(siteCurrencyAtom);

    if (maximumFractionDigits < 0) {
        value = Math.round(value * 10 ** maximumFractionDigits) * 10 ** -maximumFractionDigits;
        maximumFractionDigits = 0;
    }

    const options: Intl.NumberFormatOptions = {
        style: "currency",
        currency: currency ?? siteCurrency,
        // NB: This is mandatory. Set to a large number of digits to simulate "no rounding".
        maximumFractionDigits,
    };

    const formatter = useNumberFormatter(options);
    const noFractionFormatter = useNumberFormatter({ ...options, minimumFractionDigits: 0 });

    const parts = formatter.formatToParts(value);
    // Use default currency rounding when there is a fractional part, otherwise _allow_ for no fractional digits
    // (depends on browser locale and currency)
    const isFractionAllZeros =
        hideTrailingZeroFractions && /^0+$/.test(parts.find((part) => part.type === "fraction")?.value ?? "");
    const isHideZero = noZeroFractions && value === 0;
    return isFractionAllZeros || isHideZero ? noFractionFormatter.format(value) : formatter.format(value);
}

export interface StandardCurrencyProps {
    value: number;
}

export interface OtherCurrencyProps extends StandardCurrencyProps {
    currency: string;
}

/** Used for monthly cost estimates. Automatically rounds to a smaller number of digits. */
export function CurrencyMonthly({
    value,
    accurate = false,
}: StandardCurrencyProps & {
    /** Add 1 extra digit of accuracy (compared to usual) before removing fractions (for eg. price modal) */
    accurate?: boolean;
}) {
    const siteCurrencyFormat = useSiteCurrencyFormat();

    const hasLotsOfDigits = siteCurrencyFormat.decimal_digits === 0;
    const estPriceThreshold = hasLotsOfDigits ? (accurate ? Infinity : 10000) : accurate ? 100 : 10;
    const estPriceDigits = hasLotsOfDigits ? -3 : 0;

    const monthlyValue = hourlyToMonthly(value);
    const maximumFractionDigits =
        monthlyValue >= estPriceThreshold ? estPriceDigits : siteCurrencyFormat.decimal_digits;

    return <Currency value={monthlyValue} maximumFractionDigits={maximumFractionDigits} />;
}

/** Used for hourly cost estimates. */
export function CurrencyHourly({
    value,
    compact = false,
}: StandardCurrencyProps & {
    /** Hide fractions when currency does not normally show them */
    compact?: boolean;
}) {
    const siteCurrencyFormat = useSiteCurrencyFormat();
    const maximumFractionDigits = compact && siteCurrencyFormat.decimal_digits === 0 ? 0 : undefined;
    return <Currency value={value} maximumFractionDigits={maximumFractionDigits} />;
}

/* Use for showing promotional amounts. Removes trailing zeroes whenever possible (to be compact / easy to read). */
export function CurrencyPromo({ value }: StandardCurrencyProps) {
    return <Currency value={value} hideTrailingZeroFractions={true} />;
}

/** Used for balances & sums and other amounts that need to be displayed accurately. Always shows currency-appropriate
 * fractional digits. */
export function CurrencyBalance({ value }: StandardCurrencyProps) {
    const siteCurrencyFormat = useSiteCurrencyFormat();
    return <Currency value={value} maximumFractionDigits={siteCurrencyFormat.decimal_digits} />;
}

export function OtherCurrencyBalance({ value, currency }: OtherCurrencyProps) {
    const currencyFormat = useCurrencyFormat(currency);
    return <Currency value={value} currency={currency} maximumFractionDigits={currencyFormat.decimal_digits} />;
}

/** Always shows full decimal digits */
export function ConversionExchangeRate({ value, currency }: OtherCurrencyProps) {
    return <Currency value={value} currency={currency} />;
}

export function CurrencySymbol() {
    const siteCurrencyFormat = useSiteCurrencyFormat();
    return siteCurrencyFormat.symbol;
}
